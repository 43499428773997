import _difference from 'lodash/difference';
import { Permission } from '../../shared/models/Permission';
import { AccountScreen } from '../../account/screens';
import { Dashboard, SupplierDashboard } from '../../dashboard/screens';
import { SupplierBusinessScreen } from '../../businesses/screens';
import { DeviceManagerWeb, DeviceUpdateWeb } from '../../devices/screens';
import { InventoryListScreen } from '../../inventory/screens';
import { TemplateListScreen } from '../../labeler/screens';
import {
    MasterSupplierScreen,
    MasterProductDetailScreen,
    MasterProductScreen,
    MasterProductLinkScreen,
    LinkBusinessSupplierScreen,
    MasterSupplierLinkScreen,
    LinkBulkProductsScreen,
    MasterSupplierDataImportScreen,
    MasterCompany,
    MasterCompanyDetail,
    MasterBrandScreen,
    MasterBrandDetailScreen,
    CompanyProductsListScreen,
    CompanyProductDetailScreen,
    CompanyProductImportScreen,
    LinkBusinessMasterCompanyScreen,
    ApproveTempLinksScreen,
    ApproveTempLinkDetailScreen

} from '../../master-data/screens';

import {
    ProductListScreen,
    ProductDetailScreen,
    SupplierProductListScreen,
} from '../../products/screens';

import {
    PlaylistScreen, PlaylistDetailScreen
} from '../../digital-signage/screens';
import {
    CustomerOrderSummaryScreen, OrderPickGroupList, CustomerOrderList
} from '../../customer-order/screens';

import {
    MasterIdentifierDuplicateScreen,
    MasterProductDuplicatesScreen,
    ProductDuplicateNameScreen,
    ProductsWithoutBarcodeScreen,
    UnmappedProductsScreen,
    MasterProductsWithoutBarcodeScreen,
    MasterProductsWithoutUOMScreen,
    MasterProductsWithoutQtyValueScreen,
    MasterProductNameUnitMismatchScreen,
    GlobalProductLookupScreen,
    ExpectedStockByWeekScreen,
    UnknownIdentifierHistoryScreen,
    StorePriceReportScreen,
    ProduceCodesScreen,
    SupplierProductListReportScreen,
    RetailerCodeNotInLinkedMasterProductIdentifierScreen,
    SupplierReorderCodeMismatchScreen,
    SupplierEntryMultiplesInSameItemScreen,
    SupplierItemsNotLinkedToMasterScreen,
    RetailersSupplierItemsNotLinkedToMasterItemScreen,
    MasterProductBrandMismatchScreen,
    MasterBusinessProductNameMismatchScreen,
    MasterProductOrphansScreen,
    PriceMissingOrZeroScreen,
    SalesDataSummary,
    MasterItemLinkedRetailerMultipleItemsScreen,
    ProductsWithMarginLessThanZeroWebScreen,
    MpIdentifiersCaseQuantityMismatchSuppProductCaseQuantityScreen,
    SuppliersLinkedToRetailersScreen,
    ProductMarginsScreen,
    SupplierROCInMultipleItemsScreen,
    RetailerCodesWithinGroupNotInMasterIdentifierScreen,
    TagsToRemoveCodeScreen,
    TagsToRemovePriceScreen,
    MasterItemLinkedToDeletedItemScreen,
    RocBySupplierCompanyScreen,
    LiquorLiterTaxMismatchReportScreen,
    ProductsMostRecentSRScreen,
    HNSuppUpdateSummaryScreen,
    CgByEBTSectorDiscSummaryScreen,
    ApnaBazaarHomeneedsDataMismatch,
    ContainerSubItemsLinks

} from '../../reports/screens';

import { StockOrderImportScreen, StockOrderListScreen } from '../../stockOrder/screens';
import { 
    StockReceiptListScreen, CreateStockReceiptScreen, ImportStockReceiptScreen, 
    SuggestedPriceByMarginScreen, SuggestedPriceByMarginDetailScreen, MarginByCgScreen , PriceRoundScreen
} from '../../stockReceipt/screens';
import { UserManagerWeb, UserDetailWebScreen } from '../../users/screens';
import { AsyncTaskListScreen } from '../../async-tasks/screens/AsyncTasksList.web';
import  BusinessQueueScreen  from '../../async-tasks/screens/BusinessQueue.web';
import BusinessSyncScreen  from '../../async-tasks/screens/BusinessSync.web';
import { DispatchNotificationsListScreen, ImportDispatchNotificationScreen } from '../../dispatch-notifications/screens';
import { CCRSHomeScreen, CCRSReportListScreen,  } from '../../ccrs/screens';
import {CCRSReportDetailListScreen } from '../../ccrs/screens';

import {
    SupplierProductsWithoutBarcodeScreen,
    SupplierProductsWithoutCaseCodeScreen,
    SupplierProductsWithoutCaseQtyScreen,
    SupplierProductsWithoutUOMScreen,
    RmsHQItemIssues,
    RmsCostPriceReport,
} from '../../reports/screens';
import { BusinessRouterPaths, MasterDataRouterPaths, SupplierRouterPaths } from './RouterPaths';
import { InvoiceDetailScreen, InvoiceListScreen } from '../../invoice/screens';

import {
    WebStoreSettings, ImportFileScreen
} from '../../web_store/screens'
import { CreateBusinessScreen, BusinessListScreen } from '../../businesses/screens';
export interface Route {
    path: string;
    component: null | React.FC | React.Component;
    requiredPermissions: string[];
}

export const RetailerRoutes: Route[] = [
    {
        path: 'empty',
        component: null,
        requiredPermissions: [],
    },
    {
        path: BusinessRouterPaths.account,
        component: AccountScreen,
        requiredPermissions: [],
    },
    {
        path: BusinessRouterPaths.dashboard,
        component: Dashboard,
        requiredPermissions: [],
    },
    {
        path: BusinessRouterPaths.deviceManager,
        component: DeviceManagerWeb,
        requiredPermissions: ['view_device'],
    },
    {
        path: BusinessRouterPaths.inventories,
        component: InventoryListScreen,
        requiredPermissions: ['view_inventory'],
    },
    {
        path: BusinessRouterPaths.labelerTemplates,
        component: TemplateListScreen,
        requiredPermissions: ['view_labelertemplate'],
    },
    {
        path: BusinessRouterPaths.stockOrderImport,
        component: StockOrderImportScreen,
        requiredPermissions: ['view_stockorder', 'add_stockorder', 'view_mastersupplier'],
    },
    {
        path: BusinessRouterPaths.stockOrderList,
        component: StockOrderListScreen,
        requiredPermissions: ['view_stockorder'],
    },
    {
        path: BusinessRouterPaths.stockReceiptList,
        component: StockReceiptListScreen,
        requiredPermissions: ['view_stockreceipt'],
    },
    {
        path: MasterDataRouterPaths.masterSuppliers,
        component: MasterSupplierScreen,
        requiredPermissions: ['view_mastersupplier'],
    },
    {
        path: MasterDataRouterPaths.masterProductDetail,
        component: MasterProductDetailScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.masterProducts,
        component: MasterProductScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.masterBrands,
        component: MasterBrandScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.masterBrandDetail,
        component: MasterBrandDetailScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.companyProductsListScreen,
        component: CompanyProductsListScreen,
        requiredPermissions: ['superuser'],
    },
    {
        path: MasterDataRouterPaths.companyProductDetail,
        component: CompanyProductDetailScreen,
        requiredPermissions: ['superuser'],
    },
    {
        path: MasterDataRouterPaths.masterProductDuplicates,
        component: MasterProductDuplicatesScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.produceCodesReport,
        component: ProduceCodesScreen,
        requiredPermissions: ['view_producecodesreport'],
    },
    {
        path: MasterDataRouterPaths.supplierProductListReport,
        component: SupplierProductListReportScreen,
        requiredPermissions: ['view_supplierproductlistreport'],
    },
    {
        path: MasterDataRouterPaths.retailerCodeNotInLinkedMasterProductIdentifierReport,
        component: RetailerCodeNotInLinkedMasterProductIdentifierScreen,
        requiredPermissions: ['view_retailtercodenotlinkedmasterproductidentifierreport'],
    },
    {
        path: MasterDataRouterPaths.masterBusinessProductNameMismatchReport,
        component: MasterBusinessProductNameMismatchScreen,
        requiredPermissions: ['view_masterbusinessproductnamemismatchreport'],
    },
    {
        path: MasterDataRouterPaths.masterProductsLink,
        component: MasterProductLinkScreen,
        requiredPermissions: ['view_masterproduct', 'view_linkmasterbusinessproduct'],
    },
    {
        path: MasterDataRouterPaths.masterBusinessSuppliersLink,
        component: LinkBusinessSupplierScreen,
        requiredPermissions: ['view_mastersupplier', 'view_linkkoronasupplierbusiness'],
    },
    {
        path: MasterDataRouterPaths.masterSuppliersLink,
        component: MasterSupplierLinkScreen,
        requiredPermissions: ['view_mastersupplier', 'view_linkmasterbusinesssupplier'],
    },
    {
        path: MasterDataRouterPaths.masterSupplierDataImport,
        component: MasterSupplierDataImportScreen,
        requiredPermissions: ['view_mastersupplier', 'view_linkmasterbusinesssupplier'],
    },
    {
        path: MasterDataRouterPaths.masterCompany,
        component: MasterCompany,
        requiredPermissions: ['view_mastersupplier', 'view_mastercompany'],
    },
    {
        path: MasterDataRouterPaths.masterCompanyDetail,
        component: MasterCompanyDetail,
        requiredPermissions: ['view_mastersupplier', 'view_mastercompany'],
    },
    {
        path: MasterDataRouterPaths.masterBulkProductsLink,
        component: LinkBulkProductsScreen,
        requiredPermissions: ['view_mastersupplier', 'view_linkmasterbusinesssupplier'],
    },
    {
        path: MasterDataRouterPaths.masterItemLinkedRetailerMultipleItemsReport,
        component: MasterItemLinkedRetailerMultipleItemsScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.masterIdentifierCQMismatchSuppProductCQReport,
        component: MpIdentifiersCaseQuantityMismatchSuppProductCaseQuantityScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.suppliersLinkedToRetailersReport,
        component: SuppliersLinkedToRetailersScreen,
        requiredPermissions: ['view_masterproduct','view_supplierslinkretailerreport'],
    },
    {
        path: MasterDataRouterPaths.retailerCodesWithinGroupNotInMasterIdentifierReport,
        component: RetailerCodesWithinGroupNotInMasterIdentifierScreen,
        requiredPermissions: ['view_masterproduct','view_retailercodeswithingroupnotinmasteridentifierreport'],
    },
    {
        path: MasterDataRouterPaths.masterItemLinkedToDeletedItemReport,
        component: MasterItemLinkedToDeletedItemScreen,
        requiredPermissions: ['view_masterproduct','view_masteritemlinkedtodeleteditemreport'],
    },
    {
        path: MasterDataRouterPaths.rocBySupplierCompany,
        component: RocBySupplierCompanyScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.masterCompanyDataImport,
        component: CompanyProductImportScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: SupplierRouterPaths.invoiceDetail,
        component: InvoiceDetailScreen,
        requiredPermissions: ['view_invoice'],
    },
    {
        path: BusinessRouterPaths.invoices,
        component: InvoiceListScreen,
        requiredPermissions: ['view_invoice'],
    },
    {
        path: BusinessRouterPaths.productDetail,
        component: ProductDetailScreen,
        requiredPermissions: ['view_product'],
    },
    {
        path: BusinessRouterPaths.productList,
        component: ProductListScreen,
        requiredPermissions: ['view_product'],
    },
    {
        path: BusinessRouterPaths.productsNotMappedReport,
        component: UnmappedProductsScreen,
        requiredPermissions: [
            'view_product',
            'view_masterproduct',
            'view_linkmasterbusinessproduct',
        ],
    },
    {
        path: BusinessRouterPaths.productsWithDuplicateNameReport,
        component: ProductDuplicateNameScreen,
        requiredPermissions: ['view_productwithduplicatename'],
    },
    {
        path: MasterDataRouterPaths.masterGlobalLookup,
        component: GlobalProductLookupScreen,
        requiredPermissions: ['superuser'],
    },
    {
        path: BusinessRouterPaths.unknownIdentifierHistoryReport,
        component: UnknownIdentifierHistoryScreen,
        requiredPermissions: ['view_unknownidentifierreport'],
    },
    {
        path: BusinessRouterPaths.expectedStockByWeekReport,
        component: ExpectedStockByWeekScreen,
        requiredPermissions: [
            'view_expectedstockbyweekbycommoditygroup',
            'view_expectedstockbyweekbysupplier',
        ],
    },
    {
        path: BusinessRouterPaths.rmsItemIssuesReport,
        component: RmsHQItemIssues,
        requiredPermissions: ['view_rmshqitemmismatch'],
    },
    {
        path: BusinessRouterPaths.rmsCostPriceReport,
        component: RmsCostPriceReport,
        requiredPermissions: ['view_rmscostpricesd3'],
    },
    {
        path: BusinessRouterPaths.productsWithoutBarcodeReport,
        component: ProductsWithoutBarcodeScreen,
        requiredPermissions: ['view_productnobarcode'],
    },
    {
        path: BusinessRouterPaths.storePriceReport,
        component: StorePriceReportScreen,
        requiredPermissions: ['view_productwithstoreprice'],
    },
    {
        path: BusinessRouterPaths.priceMissingOrZeroReport,
        component: PriceMissingOrZeroScreen,
        requiredPermissions: ['view_pricemissingorzeroreport'],
    },
    {
        path: BusinessRouterPaths.cgByEBTSectorDiscSummaryReport,
        component: CgByEBTSectorDiscSummaryScreen ,
        requiredPermissions: [''],
    },
    {
        path: BusinessRouterPaths.productsWithMarginLessThanZero,
        component: ProductsWithMarginLessThanZeroWebScreen,
        requiredPermissions: ['view_productswithmarginlessthanzeroreport'],
    },
    //
    // {
    //     path: BusinessRouterPaths.productPriceDetailbySoldScreen,
    //     component: ProductPriceDetailbySoldScreen,
    //     requiredPermissions: ['view_productpricedetail'],
    // },
    {
        path: BusinessRouterPaths.supplierEntryMultiplesInSameItemScreen,
        component: SupplierEntryMultiplesInSameItemScreen,
        requiredPermissions: ['view_supplierentrymultiplesinsameitemreport'],
    },
    {
        path: BusinessRouterPaths.productMargins,
        component: ProductMarginsScreen,
        requiredPermissions: ['view_productmarginsreport'],
    },
    {
        path: BusinessRouterPaths.supplierRocInMultipleItems,
        component: SupplierROCInMultipleItemsScreen,
        requiredPermissions: ['view_supplierrocinmultipleitemsreport'],
    },
    {
        path: BusinessRouterPaths.tagsToRemoveCode,
        component: TagsToRemoveCodeScreen,
        requiredPermissions: ['view_tagstoremovecodereport'],
    },
    {
        path: BusinessRouterPaths.tagsToRemovePrice,
        component: TagsToRemovePriceScreen,
        requiredPermissions: ['view_tagstoremovepricereport'],
    },
    {
        path: BusinessRouterPaths.liquorLiterTaxMismatch,
        component: LiquorLiterTaxMismatchReportScreen,
        requiredPermissions: ['view_liquorlitertaxmismatchreport'],
    },
    {
        path: BusinessRouterPaths.productsMostRecentSR,
        component: ProductsMostRecentSRScreen,
        requiredPermissions: ['view_productsmostrecentsrreport'],
    },
    {
        path: MasterDataRouterPaths.masterProductsWithoutBarcodeReport,
        component: MasterProductsWithoutBarcodeScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.masterProductsWithoutQtyValueReport,
        component: MasterProductsWithoutQtyValueScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.masterProductsWithoutUnitMeasureReport,
        component: MasterProductsWithoutUOMScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.masterProductNameUnitMismatchReport,
        component: MasterProductNameUnitMismatchScreen,
        requiredPermissions: ['view_masterproductnameunitmismatch'],
    },
    {
        path: MasterDataRouterPaths.masterProductBrandMismatchReport,
        component: MasterProductBrandMismatchScreen,
        requiredPermissions: ['view_masterproduct','view_masterproductbrandmismatch'],
    },
    {
        path: MasterDataRouterPaths.masterIdentifierDuplicates, //MasterIdentifierDuplicates
        component: MasterIdentifierDuplicateScreen,
        requiredPermissions: ['superuser'],
    },
    {
        path :MasterDataRouterPaths.masterProductOrphansReport,
        component:MasterProductOrphansScreen,
        requiredPermissions:['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.supplierReorderCodeMismatchReport,
        component: SupplierReorderCodeMismatchScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.supplierProductNotLinkedToMasterReport,
        component: SupplierItemsNotLinkedToMasterScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.retailersSupplierItemsNotLinkedToMasterItemReport,
        component: RetailersSupplierItemsNotLinkedToMasterItemScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.linkBusinessMasterCompany,
        component: LinkBusinessMasterCompanyScreen,
        requiredPermissions: ['view_mastersupplier', 'view_linkretailerssuppliermastercompany'],
    },
    {
        path: MasterDataRouterPaths.rtlAddedTempProductsList,
        component: ApproveTempLinksScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.rtlAddedTempProductDetail,
        component: ApproveTempLinkDetailScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.hnSuppUpdateSummary,
        component: HNSuppUpdateSummaryScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.apnaBazaarHomeneedsDataMismatch,
        component: ApnaBazaarHomeneedsDataMismatch,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: MasterDataRouterPaths.containerSubItemsLinks,
        component: ContainerSubItemsLinks,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: BusinessRouterPaths.userManager,
        component: UserManagerWeb,
        requiredPermissions: ['view_user'],
    },
    {
        path: BusinessRouterPaths.userDetail,
        component: UserDetailWebScreen,
        requiredPermissions: ['change_user'],
    },
    {
        path: BusinessRouterPaths.newUser,
        component: UserDetailWebScreen,
        requiredPermissions: ['view_user'],
    },
    {
        path: BusinessRouterPaths.businessQueueTasks,
        component: BusinessQueueScreen,
        requiredPermissions: ['view_businessqueuesettings'],
    },
    {
        path: BusinessRouterPaths.businessSyncTasks,
        component: BusinessSyncScreen,
        requiredPermissions: ['view_businessqueuesettings'],
    },
    {
        path: BusinessRouterPaths.businessList,
        component: BusinessListScreen,
        requiredPermissions: ['view_business'],
    },
    {
        path: BusinessRouterPaths.createbusiness,
        component: CreateBusinessScreen,
        requiredPermissions: ['view_business'],
    },
    {
        path: BusinessRouterPaths.businessUpdate,
        component: CreateBusinessScreen,
        requiredPermissions: ['view_business'],
    },
    {
        path: BusinessRouterPaths.deviceUpdate,
        component: DeviceUpdateWeb,
        requiredPermissions: ['view_device'],
    },
    {
        path: BusinessRouterPaths.salesDataSummary,
        component: SalesDataSummary,
        requiredPermissions: ['view_salesdatasummary'],
    },
    
    {
        path: BusinessRouterPaths.settings,
        component: null,
        requiredPermissions: [],
    },

    {
        path: BusinessRouterPaths.webStoreSettings,
        component: WebStoreSettings,
        requiredPermissions: ['superuser'],
    },
    {
        path: BusinessRouterPaths.importFile,
        component: ImportFileScreen,
        requiredPermissions: ['superuser']
    },
    {
        path: BusinessRouterPaths.dispatchNotificationsList,
        component: DispatchNotificationsListScreen,
        requiredPermissions: ['view_koronadispatchnotifications',]
    },
    {
        path: BusinessRouterPaths.dispatchNotificationsImport,
        component: ImportDispatchNotificationScreen,
        requiredPermissions: ['']
    },
    {
        path: BusinessRouterPaths.ccrsHomeScreen,
        component: CCRSHomeScreen,
        requiredPermissions: ['']
    },
    {
        path: BusinessRouterPaths.ccrsReportListScreen,
        component: CCRSReportListScreen,
        requiredPermissions: ['']
    },
    {

        path: BusinessRouterPaths.ccrsReportDetailListurl,
        component: CCRSReportDetailListScreen,
        requiredPermissions: ['']
    },
    {
        path: BusinessRouterPaths.stockReceiptsImport,
        component: ImportStockReceiptScreen,
        requiredPermissions: ['view_stockreceipt','add_stockreceipt',]
    },
    {
        path: BusinessRouterPaths.suggestPriceByMargin,
        component: SuggestedPriceByMarginScreen,
        requiredPermissions: ['view_stockreceipt']
    },
    {
        path: BusinessRouterPaths.suggestPriceByMarginDetail,
        component: SuggestedPriceByMarginDetailScreen,
        requiredPermissions: ['view_stockreceipt']
    },
    {
        path: BusinessRouterPaths.marginByCgList,
        component: MarginByCgScreen,
        requiredPermissions: ['view_stockreceipt']
    },
    {
        path: BusinessRouterPaths.priceRoundList,
        component: PriceRoundScreen,
        requiredPermissions: ['view_stockreceipt']
    },
    {
        path: BusinessRouterPaths.signagePlaylist,
        component: PlaylistScreen,
        requiredPermissions: []
    },
    {
        path: BusinessRouterPaths.customerOrderSummary,
        component: CustomerOrderSummaryScreen,
        requiredPermissions: ['view_order']
    },
    {
        path: BusinessRouterPaths.customerOrderPickGroups,
        component: OrderPickGroupList,
        requiredPermissions: ['view_customerorderpickgroup']
    },
    {
        path: BusinessRouterPaths.customerOrderPickGroups,
        component: OrderPickGroupList,
        requiredPermissions: ['view_customerorderpickgroup']
    },
    {
        path: BusinessRouterPaths.customerOrderList,
        component: CustomerOrderList,
        requiredPermissions: ['view_order']
    },
    {
        path: BusinessRouterPaths.signagePlaylistDetails,
        component: PlaylistDetailScreen,
        requiredPermissions: []
    },
];

export const getAuthorizedRoutes = (
    routeList: Route[],
    userPermissions: Permission[],
    is_superuser: boolean
) => {
    const permissions = userPermissions.map((p) => p.codename);
    let routes: Route[] = [];
    if (is_superuser) {
        return routeList;
    } else {
        routeList.forEach((r) => {
            if (_difference(r.requiredPermissions, permissions).length === 0) {
                routes.push(r);
            }
        });
    }
    return routes;
};

export const SupplierRoutes = [
    {
        path: 'empty',
        component: null,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.account,
        component: AccountScreen,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.dashboard,
        component: SupplierDashboard,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.businesses,
        component: SupplierBusinessScreen,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.invoiceDetail,
        component: InvoiceDetailScreen,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.invoices,
        component: InvoiceListScreen,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.productDetail,
        component: ProductDetailScreen,
        requiredPermissions: [],
    },
    {
        path: SupplierRouterPaths.productList,
        component: SupplierProductListScreen,
        requiredPermissions: [],
    },
    {
        path: MasterDataRouterPaths.masterSupplierProductsWithoutCaseCodeReport,
        component: SupplierProductsWithoutCaseCodeScreen,
        requiredPermissions: [],
    },
    {
        path: MasterDataRouterPaths.masterSupplierProductsWithoutBarcodeReport,
        component: SupplierProductsWithoutBarcodeScreen,
        requiredPermissions: [],
    },
    {
        path: MasterDataRouterPaths.masterSupplierProductsWithoutCaseQtyReport,
        component: SupplierProductsWithoutCaseQtyScreen,
        requiredPermissions: [],
    },
    {
        path: MasterDataRouterPaths.masterSupplierProductsWithoutUnitMeasureReport,
        component: SupplierProductsWithoutUOMScreen,
        requiredPermissions: [],
    },
    {
        path: MasterDataRouterPaths.masterProductDetail,
        component: MasterProductDetailScreen,
        requiredPermissions: ['view_masterproduct'],
    },
    {
        path: BusinessRouterPaths.settings,
        component: null,
        requiredPermissions: [],
    },
];
