import { API_CONFIG } from '../../shared/api/config';
import composeRequest, { getPaginatedResponse } from '../../shared/api/core';
import { ProcessUnknownIdentifierData, UnknownIdentifier } from '../models';
// import {StatusFilter} from '../screens/UnknownIdentifierHistory/UnknownIdentifierHistory.web'
const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
    productsNoBarcodesUrl: BASE_URL + 'korona/reports/products/no-barcode/',
    productsDuplicateNameUrl: BASE_URL + 'korona/reports/products/duplicate-name/',
    unMappedBusinessProductsUrl: BASE_URL + 'korona/unmapped-products/',
    masterSupplierProductsWithoutCaseCodeUrl:
        BASE_URL + 'korona/master/supplier-products-without-case-code/',
    masterSupplierProductsWithoutCaseQtyUrl:
        BASE_URL + 'korona/master/supplier-products-without-case-qty/',
    masterSupplierProductsWithoutIdentifiersUrl:
        BASE_URL + 'korona/master/supplier-products-without-identifiers/',
    masterSupplierProductsWithoutUnitMeasureUrl:
        BASE_URL + 'korona/master/supplier-products-without-uom/',
    masterProductsDuplicatesUrl: BASE_URL + 'korona/master/product-duplicates/',
    masterIdentifierDuplicatesURL:BASE_URL + 'korona/master/product-duplicates-identifier/',//identifier duplicates url
    masterProductsWithoutIdentifiersUrl: BASE_URL + 'korona/master/products-without-identifiers/',
    masterProductsWithoutQtyValuesUrl: BASE_URL + 'korona/master/products-without-qty-values/',
    masterProductsWithoutUnitMeasureUrl: BASE_URL + 'korona/master/products-without-uom/',
    masterProductsNameUnitMismatchUrl: BASE_URL + 'korona/master/name-unit-mismatch/',
    masterProductsBrandMismatchUrl: BASE_URL + 'korona/master/product-brand-mismatch/',
    masterSupplierProductIdentifiersUrl: BASE_URL + 'korona/master/supplier-product-identifiers/',
    rmsHQItemsReport: BASE_URL + 'korona/reports/rms/items-mismatch',
    rmsCostPriceReport: BASE_URL + 'korona/reports/rms/cost-price',
    unknownIdentifierUrl: BASE_URL + 'korona/reports/unknown-identifier-history/',
    // unknownIdentifierReportUrl: BASE_URL + 'korona/reports/unknown-identifier-history/summary/',
    processUnknownIdentifierUrl: BASE_URL + 'korona/reports/unknown-identifier/toggle-processed',
    expectedStockByWeekBySupplierUrl:
        BASE_URL + 'korona/reports/expected-stock-by-week-by-supplier/',
    expectedStockByWeekByCgroupUrl:
        BASE_URL + 'korona/reports/expected-stock-by-week-by-commodity-group/',
    productsWithStorePriceUrl: BASE_URL + 'korona/reports/products/store-price/',
    // productPriceDetailByLastSold : BASE_URL + 'korona/reports/price-detail/'
    productPriceDetailByLastSold : BASE_URL + 'korona/reports/products/price-last-sold/',
    produceCodesUrl: BASE_URL + 'korona/reports/produce-codes/',
    supplierProductListUrl : BASE_URL + 'korona/reports/supplier-product-list/',
    retailterCodeNotLinkedMasterProductIdentifierUrl : BASE_URL + 'korona/reports/retailer-code-not-in-linked-master-product-identifier/',
    supplierReorderCodeMismatchUrl : BASE_URL +'korona/reports/supplier-reorder-code-mismatch/',
    retailerBusinessIdUrl : BASE_URL + 'korona/master/retailer/business/',
    supplierEntryMultiplesInSameItemUrl : BASE_URL + 'korona/reports/supplier-entry-multiples-in-same-item/',
    supplierProductsNotLinkedToMaster : BASE_URL + 'korona/reports/supplier-items-not-linked-to-master/',
    retailersSupplierItemsNotLinkedToMasterItem : BASE_URL + 'korona/reports/retailers-supplier-items-not-linked-to-master-item/',
    updateProductRetailerUrl: BASE_URL + 'korona/update-product-retailers-supplier-record',
    updateSupplierProductUrl: BASE_URL + 'korona/update-supplier-product-record',
    addMasterProductIdentifier : BASE_URL + 'korona/reports/retailer-code-not-in-linked-master-product-identifier/add_master_product_identifier',
    masterBusinessProductNameMismatch : BASE_URL + 'korona/reports/master-business-product-name-mismatch/',
    addExcludeBusinessCodeLinkedMpi : BASE_URL + 'korona/reports/retailer-code-not-in-linked-master-product-identifier/add_exclude_bussiness_code_linked_mpi',
    getMasterProductsOrphansUrl: BASE_URL + 'korona/reports/master-product-orphans/',
    productsWithPriceMissingOrZeroUrl : BASE_URL + 'korona/reports/price-missing-or-zero/',
    salesReportByBusiness: BASE_URL + 'korona/reports/sales-data-summary/',
    masterItemLinkedRetailerMultipleItemsUrl : BASE_URL + 'korona/reports/master-item-linked-retailer-multiple-items/',
    productsWithMarginLessThanZeroUrl: BASE_URL + 'korona/reports/products-with-margin-less-than-zero/',
    mpIdentifiersCaseQuantityMismatchSuppProductCaseQuantityUrl: BASE_URL + 'korona/reports/mpi-cq-mismatch-supplier-cq/',
    suppliersLinkedToRetailersUrl: BASE_URL + 'korona/reports/suppliers-link-retailer/',
    productMarginsUrl: BASE_URL + 'korona/reports/product-margins/',
    supplierRocInMultipleItemsUrl: BASE_URL + 'korona/reports/supp-roc-multiple-items/',
    retailerCodesWithinGroupNotInMasterIdentifierUrl: BASE_URL + 'korona/reports/retailer-codes-within-group-not-in-master-identifier/',
    companiesBrandsInRtlCodeWithinGroupReportUrl : BASE_URL + 'korona/reports/get-all-companies-brands-retailer-codes-within-group-not-in-master-identifier',
    addExcludeBusinessCodeRtlWithinGrpMpiUrl: BASE_URL + 'korona/reports/retailer-codes-within-group-not-in-master-identifier/exclude-bussiness-code',
    tagsToRemoveCodeUrl: BASE_URL + 'korona/reports/tags-to-remove-code/',
    tagsToRemovePriceUrl : BASE_URL + 'korona/reports/tags-to-remove-price/',
    masterItemLinkedToDeletedItemsUrl : BASE_URL + 'korona/reports/master-item-linked-to-deleted-item/',
    rocBySuppCompanyUrl : BASE_URL + 'korona/reports/roc-by-supp-company/',
    getLiquorLiterTaxMismatchUrl : BASE_URL + 'korona/reports/liquor-liter-mismatch/',
    getApnaBazaarHomeneedsDataMismatchUrl : BASE_URL + 'korona/reports/apna-bazaar-homeneeds-data-mismatch/',
    containerSubItemsLinksUrl : BASE_URL + 'korona/reports/container-subitems-links/',
    getProductsMostRecentSRUrl : BASE_URL + 'korona/reports/prods-most-recent-sr/',
    getHNSuppUpdateSummaryUrl: BASE_URL + 'korona/reports/hm-suppl-update-summary/',
    cgByEBTSectorDiscSummaryUrl : BASE_URL + 'korona/reports/cg-ebt-sector-disc-summary/',


};

export const addMasterProductIdentifier = (data: any) => {
    const URL = API_URLS.addMasterProductIdentifier;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};

export const addExcludeBusinessCodeLinkedMpi = (data: any) => {
    const URL = API_URLS.addExcludeBusinessCodeLinkedMpi;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const getUnknownIdentifiersReport = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    totalRecs: boolean,
    orderBy?: string | number ,
    filters?: any,
    shouldExport?: boolean
) => {
    let URL;
    if (shouldExport) {
        URL = `${API_URLS.unknownIdentifierUrl}export/?business_ids=${business_id}`;
    } else {
        URL = `${
            API_URLS.unknownIdentifierUrl
        }?business_ids=${business_id}&page=${page}&page_size=${pageSize}&tot_recs_found=${totalRecs}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters && filters.length > 0) {
        filters.forEach((f: { filterString: string | null }) => {
            if (f.filterString) {
                URL = URL + f.filterString;
            }
        });
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getExpectedStockByWeek = (
    reportType: 'by_supplier' | 'by_cgroup',
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.expectedStockByWeekByCgroupUrl;
    if (reportType === 'by_supplier') {
        URL = API_URLS.expectedStockByWeekBySupplierUrl;
    }
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getproductPriceDetailByLastSold = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.productPriceDetailByLastSold;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getProductsWithStorePrice = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    totalRecs: boolean,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.productsWithStorePriceUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}&tot_recs_found=${totalRecs}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getSupplierEntryMultiplesInSameItem = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    totalRecs: boolean,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.supplierEntryMultiplesInSameItemUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}&tot_recs_found=${totalRecs}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getSupplierProductNotLinkedToMaster = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    totalRecs: boolean,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.supplierProductsNotLinkedToMaster;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}&tot_recs_found=${totalRecs}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getProduceCodes = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.produceCodesUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getRetailterCodeNotLinkedMasterProductIdentifier = (
    retailer_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.retailterCodeNotLinkedMasterProductIdentifierUrl;
    if (shouldExport) {
        URL = `${URL}export/?retl_id=${retailer_id}`;
    } else {
        URL = `${URL}?retl_id=${retailer_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getSupplierProductList = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.supplierProductListUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const processUnknownIdentifier = (
    data: ProcessUnknownIdentifierData | UnknownIdentifier
) => {
    let URL = `${API_URLS.processUnknownIdentifierUrl}`;
    return composeRequest(URL, 'PUT', JSON.stringify(data));
};

// To check if these functions are called
export const getMasterProductDuplicates = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    return getPaginatedResponse(API_URLS.masterProductsDuplicatesUrl, page, pageSize, searchTerm);
};
export const getMasterProductsUnitMismatch = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    return getPaginatedResponse(
        API_URLS.masterProductsNameUnitMismatchUrl,
        page,
        pageSize,
        searchTerm
    );
};
export const getMPWithoutIdentifiers = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    return getPaginatedResponse(
        API_URLS.masterProductsWithoutIdentifiersUrl,
        page,
        pageSize,
        searchTerm
    );
};
export const getMPWithoutQtyValues = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    return getPaginatedResponse(
        API_URLS.masterProductsWithoutQtyValuesUrl,
        page,
        pageSize,
        searchTerm
    );
};

export const getMasterIdentifierDuplicates = (
    page: number,
    pageSize: number,
    searchTerm: string | null
) => {
    return getPaginatedResponse(API_URLS.masterIdentifierDuplicatesURL, page, pageSize, searchTerm);
};

export const getRetailBusinessIds=()=>{
    let URL = API_URLS.retailerBusinessIdUrl;
    return composeRequest(URL, 'GET', null)
}


export const updateProductRetailersSupplier = (payload) =>{
    const URL =`${API_URLS.updateProductRetailerUrl}`;
    return composeRequest(URL, 'POST', JSON.stringify(payload))

};

export const updateSuppliersProduct = (payload) =>{
    const URL =`${API_URLS.updateSupplierProductUrl}`;
    return composeRequest(URL, 'POST', JSON.stringify(payload))

};







export const getMasterBusinessProductNameMismatch = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.masterBusinessProductNameMismatch;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getMasterProductOrphans=(
    page: number |null,
    pageSize: number| null,
    searchTerm: string|null,
    orderBy: string|number,
    shouldExport?: boolean

) => {
    let URL= API_URLS.getMasterProductsOrphansUrl+`?page=${page}&page_size=${pageSize}`;
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
}


export const getProductsWithPriceMissingOrZero = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    totalRecs: boolean,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.productsWithPriceMissingOrZeroUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}&tot_recs_found=${totalRecs}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getSalesReportByBusiness = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean,
    prevData?: any | null,
) => {
    let URL = API_URLS.salesReportByBusiness;
    if (shouldExport) {
        URL = `${URL}export/?business_id=${business_id}`;
    } else {
        URL = `${URL}summary/?business_id=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&ordering=${orderBy}`;
    }
    if (prevData) {
       
        URL +=  "&" + new URLSearchParams(prevData).toString();
    }
    return composeRequest(URL, 'GET', null);
};

export const getMasterItemLinkedRetailerMultipleItems = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    orderBy: string | number,
    shouldExport?: boolean
  ) => {
    let URL = API_URLS.masterItemLinkedRetailerMultipleItemsUrl
        + '?page=' + page + '&page_size=' + pageSize;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = API_URLS.productsDuplicateNameUrl
            + '?search=' + searchTerm + '&page=' + page + '&page_size=' + pageSize;
    }
    if (shouldExport) {
        URL = `${API_URLS.masterItemLinkedRetailerMultipleItemsUrl}export/`;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};



export const getProductsWithMarginLessThanZero = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.productsWithMarginLessThanZeroUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        let orderByUpdated = orderBy === 'purchase_price_converted' ? 'purchase_price' : orderBy === '-purchase_price_converted' ? '-purchase_price': orderBy
        
        URL = `${URL}&orderBy=${orderByUpdated}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getMpIdentifiersCaseQuantityMismatchSuppProductCaseQuantity = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    orderBy: string | number,
    shouldExport?: boolean
  ) => {
    let URL = API_URLS.mpIdentifiersCaseQuantityMismatchSuppProductCaseQuantityUrl
        + '?page=' + page + '&page_size=' + pageSize;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = API_URLS.mpIdentifiersCaseQuantityMismatchSuppProductCaseQuantityUrl
            + '?search=' + searchTerm + '&page=' + page + '&page_size=' + pageSize;
    }
    if (shouldExport) {
        URL = `${API_URLS.mpIdentifiersCaseQuantityMismatchSuppProductCaseQuantityUrl}export/`;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getSuppliersLinkedToRetailers = (
    page: number,
    pageSize: number,
    searchTerm: string | null,
    totalRecs: boolean,
    orderBy: string | number,
    filters?: string,
    shouldExport?: boolean
  ) => {
    let URL = API_URLS.suppliersLinkedToRetailersUrl
        + '?page=' + page + '&page_size=' + pageSize + '&tot_recs_found=' + totalRecs;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = API_URLS.suppliersLinkedToRetailersUrl
            + '?search=' + searchTerm + '&page=' + page + '&page_size=' + pageSize;
    }
    if (shouldExport) {
        URL = `${API_URLS.suppliersLinkedToRetailersUrl}export/`;
    }
    if (orderBy) {
        URL = `${URL}&orderBy=${orderBy}`;
    }
    if (filters) {
        URL += filters;
    }
    return composeRequest(URL, 'GET', null);
};


export const getProductMargins = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    totalRecs: boolean,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.productMarginsUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}&tot_recs_found=${totalRecs}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        // let orderByUpdated = orderBy === 'purchase_price_converted' ? 'purchase_price' : orderBy === '-purchase_price_converted' ? '-purchase_price': orderBy
        
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
}



export const getSupplierROCInMultipleItems = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    totalRecs: boolean,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.supplierRocInMultipleItemsUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}&tot_recs_found=${totalRecs}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getCompaniesBrandsInRtlCodeWithinGroupReport = () => {
    const URL = API_URLS.companiesBrandsInRtlCodeWithinGroupReportUrl;
    return composeRequest(URL, 'GET', null);
};

export const getRetailerCodesWithinGroupNotInMasterIdentifier = (
    page: number, 
    pageSize: number, 
    searchTerm: string, 
    totalRecs: boolean,
    orderBy?: string | number ,
    filters?: string | null, 
    shouldExport?: boolean
) => {
    let URL = API_URLS.retailerCodesWithinGroupNotInMasterIdentifierUrl + '?page=' + page + '&page_size=' + pageSize + '&tot_recs_found=' + totalRecs ;
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = API_URLS.retailerCodesWithinGroupNotInMasterIdentifierUrl + '?search=' + searchTerm + '&page=' + page + '&page_size=' + pageSize;
    }
    if (shouldExport) {
        URL = `${API_URLS.retailerCodesWithinGroupNotInMasterIdentifierUrl}export/?search=''`;
    }
    if (filters) {
        URL += filters;
    } 
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const addExcludeBusinessCodeRtlWithinGrpMpi = (data: any) => {
    const URL = API_URLS.addExcludeBusinessCodeRtlWithinGrpMpiUrl;
    return composeRequest(URL, 'POST', JSON.stringify(data));
};


export const getTagsToRemoveCode = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    totalRecs: boolean,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.tagsToRemoveCodeUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}&tot_recs_found=${totalRecs}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
}

export const getTagsToRemovePrice = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    totalRecs: boolean,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.tagsToRemovePriceUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}&tot_recs_found=${totalRecs}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
}


export const getMasterItemLinkedToDeletedItems = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.masterItemLinkedToDeletedItemsUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
}


export const getRocBySupplier = (
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    filters: string | null,
    orderBy?: string | number ,
    shouldExport?: boolean
) => {
    let URL = API_URLS.rocBySuppCompanyUrl;
    if (shouldExport) {
        URL = `${URL}export/`;
    } else {
        URL = `${URL}?page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
}



export const getLiquorLiterTaxMismatch = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    shouldExport?: boolean
) => {
    let URL = API_URLS.getLiquorLiterTaxMismatchUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getApnaBazaarHomeneedsDataMismatch = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    shouldExport?: boolean
) => {
    let URL = API_URLS.getApnaBazaarHomeneedsDataMismatchUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};

export const getContainerSubItemsLinksUrl = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    shouldExport?: boolean
) => {
    let URL = API_URLS.containerSubItemsLinksUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getProductsMostRecentSR = (
    business_id: number | string,
    korona_supp_id: string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    shouldExport?: boolean
) => {
    let URL = API_URLS.getProductsMostRecentSRUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_id=${business_id}&korona_supp_id=${korona_supp_id}`;
    } else {
        URL = `${URL}?business_id=${business_id}&korona_supp_id=${korona_supp_id}&page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};


export const getHNSuppUpdateSummary = (
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    orderBy?: string | number ,
    shouldExport?: boolean
) => {
    let URL = API_URLS.getHNSuppUpdateSummaryUrl;
    if (shouldExport) {
        URL = `${URL}export/`;
    } else {
        URL = `${URL}?page=${page}&page_size=${pageSize}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
}

export const getCgByEBTSectorDiscSummary = (
    business_id: number | string,
    page: number | null,
    pageSize: number | null,
    searchTerm: string | null,
    totalRecs: boolean,
    orderBy?: string | number ,
    filters?: string | null,
    shouldExport?: boolean
) => {
    let URL = API_URLS.cgByEBTSectorDiscSummaryUrl;
    if (shouldExport) {
        URL = `${URL}export/?business_ids=${business_id}`;
    } else {
        URL = `${URL}?business_ids=${business_id}&page=${page}&page_size=${pageSize}&tot_recs_found=${totalRecs}`;
    }
    searchTerm = searchTerm ? searchTerm.trim() : '';
    if (searchTerm) {
        URL = `${URL}&search=${searchTerm}`;
    }
    if (filters) {
        URL += filters;
    }
    if(orderBy){
        URL = `${URL}&orderBy=${orderBy}`;
    }
    return composeRequest(URL, 'GET', null);
};